<template>
  <div>
    <h1 class="text-h4">Управление товарами</h1>
    <v-divider class="my-2"></v-divider>
    <v-form v-model="form.valid" :disabled="busy" @submit.prevent="submitForm">
      <v-card>
        <v-card-title>Видео обзор товара {{ itemCode }}</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="form.data.name"
            label="Название"
            counter="150"
            disabled
            outlined
          />
          <v-textarea
            v-model="form.data.reviewVideo"
            label="Описание"
            counter="5000"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!--          <v-btn :to="{name: 'item.view', params: { id }}">Отмена</v-btn>-->
          <v-btn @click="$router.back()">Назад</v-btn>
          <v-btn color="primary" :disabled="!form.valid" type="submit">Отправить</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import { dashboardPageCommonAttributes } from '@/mixins/DashboardPageCommonAttributes'
import { mapActions, mapState } from 'vuex'

export default {
  ...dashboardPageCommonAttributes,
  name: 'ItemCategoryEditPage',
  props: {
    id: {
      type: [String],
      required: true
    }
  },
  data() {
    return {
      form: {
        valid: true,
        data: {
          name: null,
          reviewVideo: null
        }
      }
    }
  },
  computed: {
    ...mapState('item_video_edit', ['item', 'busy']),
    itemCode() {
      if (this.item.codeBs) {
        return this.item.code + ' (' + this.item.codeBs + ')'
      }

      return this.item.code
    }
  },
  created() {
    this.fetchData({ id: this.id })
      .then(() => {
        this.form.data.name = this.item.name
        this.form.data.reviewVideo = this.item.reviewVideo
      })
  },
  methods: {
    ...mapActions('item_video_edit', ['fetchData', 'updateReviewVideo']),
    submitForm() {
      this.updateReviewVideo({ reviewVideo: this.form.data.reviewVideo })
        .then(() => this.$router.back())
        // .then(() => this.$router.push({ name: 'item.view', params: { id: this.id } }))
    }
  }
}
</script>

<style scoped>

</style>
